import React from 'react';

class SocialsList extends React.Component {
    
    render() {
        return (
            <ul>
                <li>
                    <a href="https://www.linkedin.com/in/emanuele-lena-2ab16a152/" title="Emanuele Lena" target="_blank" rel="noopener noreferrer">
                        Linkedin
                    </a>
                </li>
                {/* <li>
                    <a href="https://www.instagram.com/webl.agency/" title="webl.agency" target="_blank" rel="noopener noreferrer">
                        Instagram
                    </a>
                </li> */}
                <li>
                    <a href="https://github.com/emanuelena49/" title="emanuelena49" target="_blank" rel="noopener noreferrer">
                        Github
                    </a>
                </li>
            </ul>
        );
    }
}

export default SocialsList;
import React from 'react';
import './SmartImageContainer.scss';
// import {Link} from 'react-router-dom';

interface SmartImageContainerProps {
    href? : string, 
    opacBg? : boolean, 
    title?: string, 
    target?: string, 
    height?: string, 
    src: string
}

/**
 * An Image container that inplement:
 * -    the possibility to set the height (the img will be automatically centred)
 * -    the possibility to use a cliccable url (w some cool effects)
 */
class SmartImageContainer extends React.Component<SmartImageContainerProps> {

    render() {
        // add eventual clickable link
        let content;

        if (typeof this.props.href === "string") {

            let opac = ""
            if (this.props.opacBg !== false) {
                opac = " smart-image-hover-opac"
            }

            if (this.props.href.charAt(0) === '/') {
                content = <a href={this.props.href} 
                    title={this.props.title || ""}
                    target={this.props.target || ""} >
                    <div className={"smart-image-hover" + opac} ></div>
                    <div className="smart-image-container-content">
                        {this.props.children}
                    </div>
                </a>;
            } else {
                content = <a href={this.props.href} 
                    title={this.props.title || ""}
                    target={this.props.target || ""} >
                    <div className={"smart-image-hover" + opac} ></div>
                    <div className="smart-image-container-content">
                        {this.props.children}
                    </div>
                </a>;
            }
            
        } else {
            content = <div className="smart-image-container-content">
                {this.props.children}
            </div>;
        }

        return <div className="smart-image-container" style={{
            "height": this.props.height || "100%", 
            "backgroundImage": "url(" + this.props.src + ")"
        }} >
            {content}
        </div>
    }
}


export default SmartImageContainer;
export {SmartImageContainer};
export type {SmartImageContainerProps};

import React from 'react'
import { Squash as Hamburger } from 'hamburger-react'
import './Menu.scss';
import MenuVoice from './MenuVoice';

class Menu extends React.Component {

    constructor() {

        super();

        this.state = {
            'mobile-menu':  'close'     // | mobile-menu-open
        };
    }

    render() {
        
        // manage mobile
        let menuMobileClass, toggled;

        if (this.state["mobile-menu"] === 'open') {
            menuMobileClass = " menu-mobile-open";
            toggled = true;
        } else {
            menuMobileClass = "";
            toggled = false;
        }

        return <div className="menu-container">
            <div className="menu-icon-mobile">
                <Hamburger toggled={toggled} toggle={this.toggledMenuIcon}></Hamburger>
            </div>
            <div className={"menu" + menuMobileClass}>
                <nav>
                    <ul>
                        <MenuVoice href={""} title="Vai alla home page" onClick={this.toggledMenuIcon} >Home</MenuVoice><span className="sep"> | </span> 
                        <MenuVoice href={"/progetti-vari"} title="Progetti vari" onClick={this.toggledMenuIcon} >Progetti Vari</MenuVoice><span className="sep"> | </span> 
                        <MenuVoice href={"/siti-web"} title="Siti Web" onClick={this.toggledMenuIcon} >Siti Web</MenuVoice><span className="sep"> | </span> 
                        <MenuVoice href={"/contatti"} title="Contattami ora" onClick={this.toggledMenuIcon} >Contatti</MenuVoice>
                    </ul>
                </nav>
            </div>
        </div>
    }

    toggledMenuIcon = (refresh) => {

        if (this.state["mobile-menu"] === 'close') {
            this.setState({
                'mobile-menu':  'open'
            });
        } else {
            this.setState({
                'mobile-menu':  'close'
            });
        }
    }
}

export default Menu;
import React from 'react';

class ContactsList extends React.Component {
    
    render() {
        return (
                    <ul>
                        <li>
                            email: <a href="mailto:emanuelelena.it@gmail.com" title="Inviami un'e-mail">
                                emanuelelena.it@gmail.com
                            </a>
                        </li>
                        {/* <li>
                            telefono: <a href="tel:+393286897251" title="Chiamami ora">
                                +39 328 689 7251
                            </a>
                        </li>
                        <li>
                            Whatsapp: <a href="https://api.whatsapp.com/send?phone=+393286897251" 
                                title="Inviami un messaggio" target="_blank" rel="noopener noreferrer">
                                Inviami un messaggio
                            </a> 
                        </li> */}
                    </ul>
        );
    }
}

export default ContactsList;
import React from 'react';
import CentredContent from '../../components/CentredContent/CentredContent';
import Spacer from '../../components/Spacer/Spacer';
import ProjectBox from '../../components/ProjectBox/ProjectBox';
import {Project} from '../../classes/Project';
import projectsModel from '../../models/projectsModel';
import { fetcher } from '../../classes/utils/Fetcher';
import FetchAllProjects from '../../classes/project/ProjectsFetchCommands';

interface ProjectPageState {
    loading: boolean
}

interface ProjectPageProps {

    /** The key I use to identify a project, it is the same used in URL path */
    projectKey: string,
}


class ProjectPage extends React.Component<ProjectPageProps, ProjectPageState> {

    project?: Project | null = null; 

    constructor(props: ProjectPageProps) {
        super(props);

        this.state = {loading: true}
    }

    componentDidMount() {

        fetcher.execute(new FetchAllProjects())
            .finally(() => {

                // get project data
                this.project = projectsModel.getProjectByKey(this.props.projectKey);

                this.setState({
                    ...this.state,
                    loading: false, 
                });
            });
    }

    render() {

        if (this.state.loading) return <div className="page-project-page">
            <CentredContent>
                <Spacer height="100px" />
                <p>
                    <a href="/progetti-vari" title="Vedi tutti i progetti">
                        {"<- Tutti i progetti"} 
                    </a>
                </p>
                <Spacer height="50px"/>
                <span>...Caricamento...</span>
                <Spacer height="400px" />
            </CentredContent>
        </div>;

        if (this.project === null) {
            return(
                <div className="page-project-page">
                    <CentredContent>
                        <Spacer height="100px" />
                        <p>
                            <a href="/progetti-vari" title="Vedi tutti i progetti">
                                {"<- Tutti i progetti"} 
                            </a>
                        </p>
                        <Spacer height="50px"/>
                        <h1>Errore 404: Il progetto selezionato non esiste</h1>
                        <p>
                            Torna a <a href="progetti-vari" title="Progetti vari">tutti i progetti</a>.
                        </p>
                    </CentredContent>
                </div>
            )
        }

        return (
            <div className="page-project-page">
                <CentredContent>

                    <Spacer height="100px" />

                    <p>
                        <a href="/progetti-vari" title="Vedi tutti i progetti">
                            {"<- Tutti i progetti"} 
                        </a>
                    </p>
                    <Spacer height="50px"/>

                    <ProjectBox project={this.project!} />

                    <Spacer />
                    
                </CentredContent>
            </div>
        );
    }
}

export default ProjectPage;
export {ProjectPage};
export type {ProjectPageProps};
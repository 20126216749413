import React from 'react';
import {Row, Col} from 'react-flexbox-grid';
import './Home.scss';
import CentredContent from '../../components/CentredContent/CentredContent';
import Spacer from '../../components/Spacer/Spacer';
import SmartImageContainer from '../../components/SmartImageContainer/SmartImageContainer';
import Portrait from '../../components/Portrait/Portrait'


class Home extends React.Component {
    render() {
        return (
            <div className="home-page">
                <div className="atf">
                    <CentredContent>
                        <Row>
                            <Col xs={6} xsOffset={3} sm={3} smOffset={0}>
                                <div className="atf-logo">
                                    <img src="img/logo-ombra.png" alt="Emanuele Lena Sviluppo Software"></img>
                                </div>
                            </Col>
                            <Col xs={12} xsOffset={0} sm={8} smOffset={1}>
                                <div className="atf-title">
                                    <h1>Emanuele Lena</h1>
                                    <h2>
                                        Laureato magistrale in Informatica, sviluppatore software 
                                        e data scientist
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                    </CentredContent>
                </div>

                <div className="projects-categories-bar">
                    <div className="project-category">
                        <SmartImageContainer src="img/progetti/uniud-sailing-lab.jpg" href="/progetti-vari" alt="progetti personali sviluppo software" title="Vedi Altri Progetti">
                            <h2 className="category-name">Progetti personali</h2>
                            {/* TODO: add subtitle */}
                            <h3 className='category-description'>
                                Progetti personali e universitari
                            </h3>
                        </SmartImageContainer>
                    </div>

                    <div className="project-category">
                        <SmartImageContainer src="img/progetti/sito-responsive.png" href="/siti-web" alt="portfolio siti web" title="Vedi Portfolio Siti Web" >
                            <h2 className="category-name">Siti Web</h2>
                            {/* TODO: add subtitle */}
                            <h3 className='category-description'>
                                Siti web sviluppati<br/>
                                come libero professionista
                            </h3>
                        </SmartImageContainer>
                    </div>
                </div>

                <Spacer height="50px"></Spacer>

                <div className="about-me-short">
                    <CentredContent>
                        <Row>
                            <Col xs={12} sm={4}>
                                <div className="about-me-short-picture">
                                    <Portrait src={"/img/profilo-nuova.jpg"} alt="Emanuele Lena Sviluppo Web" title="Questo sono io">
                                    </Portrait>
                                </div>
                            </Col>
                            <Col xs={12} sm={8}>
                                <div className="about-me-short-description">
                                    <div className="about-me-short-description-content">
                                        <h3>Qualcosa su di me (in breve)</h3>
                                        <ul>
                                            <li>
                                                <strong>Chi sono:</strong> mi chiamo Emanuele.
                                            </li>
                                            <li>
                                                <strong>Cosa ho studiato:</strong> sono laureato triennale e 
                                                magistrale all'Università di Udine rispettivamente
                                                in <a href="https://www.uniud.it/it/didattica/corsi/area-scientifica/scienze-matematiche-informatiche-multimediali-fisiche/laurea/internet-of-things-big-data-web/corso/internet-of-things-big-data-web"
                                                target='_blank' rel="noreferrer">
                                                Internet of Things, 
                                                Big Data & Web</a> e <a 
                                                href="https://www.uniud.it/it/didattica/info-didattiche/piano-di-studio/laurea-magistrale-informatica/2021-2022#autotoc-item-autotoc-4"
                                                target="_blank" rel="noreferrer">Informatica
                                                </a> (<em>entrambe le lauree 
                                                conseguite in corso
                                                e con voto <strong>110L</strong></em>).
                                            </li>
                                            <li>
                                                <strong>Che cosa so fare:</strong> progettazione e sviluppo di software
                                                (prevalentemente lato backend e sistemi distribuiti, linguaggio principale: Python),
                                                raccolta e analisi dei requisiti,
                                                modellazione UML, refactoring,
                                                testing automatizzato,
                                                lavoro in contesto AGILE,
                                                raccolta, elaborazione e analisi statistica di dati,
                                                machine learning e sviluppo di sistemi basati sui dati,
                                                utilizzo di (text) generative AI.
                                            </li>
                                            <li>
                                                <strong>Che cosa faccio adesso:
                                                </strong> attualmente, lavoro
                                                come <em>software engineer
                                                </em> presso <a
                                                href='https://designcoaching.net/' target='_blank' rel="noreferrer">IDS Srl</a>,
                                                e <a
                                                href='https://www.skao.int/' target='_blank' rel="noreferrer">
                                                SKAO</a> <em>(aggiornato il 15/09/2024)</em>.
                                            </li>
                                        </ul>
                                        <Spacer height="20px"></Spacer>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <h3>Formazione</h3>

                        <p>
                            A ottobre 2021 ho completato la mia 
                            laurea triennale in <a
                            href="https://www.uniud.it/it/didattica/corsi/area-scientifica/scienze-matematiche-informatiche-multimediali-fisiche/laurea/internet-of-things-big-data-web/corso/internet-of-things-big-data-web"
                            target='_blank' rel="noreferrer">Internet of Things, 
                            Big Data & Web</a> con una 
                            tesi sullo <a href="/progetti-vari/uniud-sailing-lab-neptune-2-0"
                            title="Progettazione e sviluppo di Neptune 2.0">sviluppo di un software per 
                            l'assistenza alla navigazione a vela</a> prendendo
                            il massimo dei voti (<strong>110L</strong>)
                            e venendo riconosciuto come il miglior studente del
                            mio corso per il mio anno di laurea.
                        </p>
                        <p>
                            Sempre a ottobre 2021 ho iniziato la mia laurea
                            magistrale in Informatica, scegliendo la
                            specializzazione in <a 
                            href="https://www.uniud.it/it/didattica/info-didattiche/piano-di-studio/laurea-magistrale-informatica/2021-2022#autotoc-item-autotoc-4"
                            target="_blank" rel="noreferrer">Progetto e Sviluppo Software</a>.
                            Durante questi due anni ho avuto modo di approfondire
                            - sia con lo studio teorico che
                            con <a href="/progetti-vari/"
                            title="Progetti pratici vari">progetti pratici
                            e/o di ricerca</a> tematiche come:
                        </p>
                        <ul>
                        <li>
                            la progettazione e lo sviluppo di software, 
                            in particolare secondo il paradigma Object-Oriented
                            (ma anche con qualche accenno allo stile funzionale),
                            approfondendo principalmente temi come i design pattern,
                            la modellazione UML, il refactoring, 
                            la qualità del codice, il testing automatizzato
                            e i sistemi distribuiti;
                        </li>
                        <li>
                            la gestione del processo di sviluppo software
                            con metodologie AGILE (SCRUM) e la visione del problema
                            da prospettive non stettamente tecniche
                            (con corsi come Informatica e Diritto, Sistemi 
                            Informativi e Data Warehouse e Ingegneria del Software);
                        </li>
                        <li>
                            la raccolta, l'elaborazione e l'analisi di dati
                            usando metodi statistici, tecniche di NLP,
                            tecniche di analisi dei grafi e delle reti (sociali),
                            algoritmi di machine learning supervised e unsupervised
                            e Large Language Model;
                        </li>
                        <li>
                            la realizzazione e la valutazione di sistemi basati
                            su dati e/o modelli di machine learning (e.g., <em>
                            Information Retrieval</em>, <em>
                            Recommender Systems</em>).
                        </li>
                        </ul>
                        <p>
                            A marzo 2024 mi sono laureato - <em>di nuovo
                            con voto <strong>110L</strong></em> - con 
                            una tesi in <a href="/progetti-vari/tesi-magistrale-machine-learning-software-testing"
                            title='Tesi di laurea Magistrale - Data Mining su Rapporti
                            di Test di Sistema del Software
                            usando Machine Learning e
                            Large Language Model'>machine learning
                            e Large Language Model applicati ai dati dei rapporti
                            di test di sistema del software</a>.
                        </p>
                        <h3>Esperienze lavorative</h3>
                        <p>
                            Durante i miei studi ho lavorato come libero professionista
                            nell'ambito dello sviluppo web. Nel corso di questa
                            esperienza mi sono occupato prevalentemente di 
                            progettare e realizzare siti web per
                            conto di piccole-medie aziende, svolgendo in autonomia
                            anche le attività relative al
                            rapporto con il cliente e all'analisi dei requisiti.
                        </p>
                        <p>
                            Attualmente lavoro
                            come <em>software engineer</em> presso la società 
                            di consulenza <a href='https://designcoaching.net/'
                            target='_blank' rel="noreferrer">IDS Srl</a>. La mia occupazione principale
                            è la progettazione e lo sviluppo di strumenti e librerie
                            a supporto del testing di integrazione nel contesto
                            del progetto di realizzazione di un
                            radio-telescopio (<a href='https://www.skao.int/'
                            target='_blank' rel="noreferrer">SKAO</a>). Oltre a questo mi occupo
                            anche di performance testing e di sviluppo di
                            prodotti basati su generative AI. Attualmentente
                            sto saltuariamente studiando tematiche come il
                            l'unit testing, i test di integrazione, 
                            le architteture del software,
                            il Domain Driven Design,
                            la qualità del codice,
                            l'uso di generative AI nell'ambito dell'Ingegneria
                            del software e la Retrieval Augmented Generation.
                        </p>
                    </CentredContent>
                </div>

                <Spacer height="50px"></Spacer>
            </div>
        );
    }
}

export default Home;
import React from 'react';
import './Portfolio.scss';
import {Row, Col} from 'react-flexbox-grid';
import CentredContent from '../../components/CentredContent/CentredContent';
import Spacer from '../../components/Spacer/Spacer';
import SmartImageContainer from '../../components/SmartImageContainer/SmartImageContainer';


class Portfolio extends React.Component {
    render() {
        return (
            <div className="page-portfolio">
                <CentredContent>
                <Spacer height="150px" />
                <h1>Siti web realizzati per conto di clienti</h1>
                <h2>Attività da libero professionista, tra il 2020 e il 2023</h2>
                <Spacer height="50px" />

                <div className="websites-grid">
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className="websites-grid-cell">
                                <SmartImageContainer href="https://www.sport-cycling.it" target="_blank" rel="noreferrer"
                                    src="https://www.sport-cycling.it/wp-content/uploads/2019/04/logo2.jpg" 
                                    opacBg={true} height="300px"
                                    alt="Sport Cycling sito web" title="Sport Cycling - Vedi Sito" >
                                        <div className="site-name">
                                            <h3>Sport-Cycling</h3>
                                            <p>
                                                Un sito vetrina dinamico - aggiornato regolarmente con nuovi 
                                                prodotti divisi per categorie - potenziato con attività di SEO locale [Wordpress].
                                            </p>
                                            <p>www.sport-cylcing.it</p>
                                        </div>
                                </SmartImageContainer>
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className="websites-grid-cell">
                                <SmartImageContainer href="https://www.isolmecar.com/" target="_blank" rel="noreferrer"
                                    src="img/progetti/sito-responsive.png" opacBg={true} height="300px"
                                    alt="IsolMeCar SRL" title="IsolMeCar SRL - Vedi Sito" >
                                        <div className="site-name">
                                            <h3>ISOL.ME.CAR. SRL</h3>
                                            <p>
                                                Sito aziendale multilingua con target internazionale, 
                                                esposizione prodotti con materiale multimediale [Wordpress].
                                            </p>
                                            <p>www.isolmecar.com</p>
                                        </div>
                                </SmartImageContainer>
                            </div>
                        </Col>
                        {/* <Col xs={12} sm={6} lg={4}> */}
                        <Col xs={12} sm={6}>
                            <div className="websites-grid-cell">
                                <SmartImageContainer href="https://www.brottomarcello.it" target="_blank" rel="noreferrer"
                                    src="img/progetti/sito-vini.png" opacBg={true} height="300px"
                                    alt="Brotto Marcello" title="Brotto Marcello - Vedi Sito" >
                                        <div className="site-name">
                                            <h3>Azienda Agricola Brotto Marcello</h3>
                                            <p>
                                                Sito vetrina dinamico, esposizione catalogo vini, 
                                                sezione news [Wordpress].
                                            </p>
                                            <p>www.brottomarcello.it</p>
                                        </div>
                                </SmartImageContainer>
                            </div>
                        </Col>
                        {/* <Col xs={12} sm={6} lg={4}> */}
                        <Col xs={12} sm={6}>
                            <div className="websites-grid-cell">
                                <SmartImageContainer href="https://www.pelizzariegallo.it" target="_blank" rel="noreferrer"
                                    src="img/progetti/sito-casa-serramenti.png" opacBg={true} height="300px"
                                    alt="Pelizzari & Gallo" title="Pelizzari & Gallo - Vedi Sito" >
                                        <div className="site-name">
                                            <h3>Pelizzari & Gallo - Remova SRLS</h3>
                                            <p>
                                                Sito vetrina [Wordpress].
                                            </p>
                                            <p>www.pelizzariegallo.it</p>
                                        </div>
                                </SmartImageContainer>
                            </div>
                        </Col>
                        {/* <Col xs={12} sm={12} lg={4}>
                            <div className="websites-grid-cell">
                                <div className="websites-grid-cta">
                                    <div className="websites-grid-cta-content">
                                        <h3>Vuoi essere il prossimo?</h3>
                                        <p>
                                            <Link to='/contatti'>   
                                                <span className="cta-button">
                                                    Contattami ora
                                                </span>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </CentredContent>
            </div>
        );
    }
}

export default Portfolio;
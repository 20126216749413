/**
 * A generic tag to "say which topics" cover a project
 */
abstract class ProjectTag {

    public abstract get id(): number | undefined;
    public abstract get name(): string;
    public abstract get iconSrc(): string | undefined;

    public abstract set id(value: number | undefined);
    public abstract set name(value: string);
    public abstract set iconSrc(value: string | undefined);

    /**
     * Get a classic JavaScript object from this
     * 
     * @returns a classic JavaScript object
     */
    public toObject(): Object {
        return {
            "id": this.id, 
            "name": this.name, 
            "iconSrc": this.iconSrc,
        };
    }

    /**
     * Fill this object with values passed in a JavaScript object
     * @param data 
     */
    public fillFromObject(data: {id?: number, name: string, iconSrc?: string}): void {
        this.id = data.id;
        this.name = data.name;
        this.iconSrc = data.iconSrc;
    }
}

class ConcreteProjectTag extends ProjectTag {
 
    public get id(): number | undefined {
        return this._id;
    }
    public get name(): string {
        return this._name;
    }
    public get iconSrc(): string | undefined {
        return this._iconSrc;
    }

    public set id(value: number | undefined) {
        this._id = value;
    }
    public set name(value: string) {
        this._name = value;
    }
    public set iconSrc(value: string | undefined) {
        this._iconSrc = value;
    }

    private _id?: number;
    private _name!: string;
    private _iconSrc?: string;

    constructor(data: {id?: number, name: string, iconSrc?: string})  {

        super();

        this.fillFromObject(data);
    }
}

export default ProjectTag;
export {ProjectTag, ConcreteProjectTag};

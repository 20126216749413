import React from "react";
import { ProjectTag } from "../../classes/Project";
import projectsModel, { ProjectsModel } from "../../models/projectsModel";
import { TagsModel } from "../../models/tagsModel";
import ProjectPreviewBox from "./ProjectPreviewBox";
import Select, { MultiValue } from 'react-select';
import { Col, Row } from "react-flexbox-grid";
import Spacer from "../Spacer/Spacer";
import ProjectsLoader from "../ProjectLoader/ProjectsModelLoader";
import { fetcher } from "../../classes/utils/Fetcher";
import FetchAllProjects from "../../classes/project/ProjectsFetchCommands";

interface ProjectsListProps {
    projectsModel: ProjectsModel, 
    tagsModel: TagsModel, 
}

interface ProjectsListState {
    
    /** Which project tags am I intrested in? */
    includeTags: Array<ProjectTag> | [], 

    /** should I add more space to avoid click issue or not? */
    isFilterMenuOpen: boolean, 

    loading: boolean, 
}

class ProjectsList extends React.Component<ProjectsListProps, ProjectsListState> {

    projectsModel: ProjectsModel;
    tagsModel: TagsModel;

    constructor(props: ProjectsListProps) {
        super(props);

        this.projectsModel = props.projectsModel;
        this.tagsModel = props.tagsModel;

        this.state = {includeTags: [], isFilterMenuOpen: false, loading: true};
    }

    componentDidMount() {

        fetcher.execute(new FetchAllProjects())
            .finally(() => {
                this.setState({
                    ...this.state,
                    loading: false, 
                });
            });
    }

    render() {

        if (this.state.loading) return <span>...Caricamento...</span>;

        return (
            <div className="project-list-container">
                <Row className="project-list-filters" style={{zIndex: 5000}}>
                    <Col xs={12} md={10} lg={8}>
                        <Select isMulti
                            options={Array.from(this.tagsModel.tags).map(([key, tag]) => {
                                return {label: tag.name, value: tag}
                            })}
                            placeholder="Filtra per tag"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onMenuOpen={this.onFilterMenuOpen.bind(this)}
                            onMenuClose={this.onFilterMenuClose.bind(this)}
                            onChange={this.onFilterChange.bind(this)}
                        />
                    </Col>
                </Row>
                <Spacer height={this.state.isFilterMenuOpen ? "350px" : "50px"}/>
                <div className="project-list">
                    {
                        // if is there any project, print it with the PreviewBox
                        this.projectsModel.getAllProjectKeys({includeTags: this.state.includeTags}).length > 0 
                        ?   this.projectsModel.getAllProjectKeys({includeTags: this.state.includeTags}).map((key, i) => {

                                return <ProjectPreviewBox project={projectsModel.getProjectByKey(key)!} 
                                    projectKey={key} key={i} />
                            })
                        :   <p>Siamo spiacenti, il progetto che stai cercando non esiste. Prova ad allentare i filtri</p>
                    }
                </div>
            </div>
        );
    }

    onFilterChange(e: MultiValue<{label: String , value: ProjectTag}>) {
        this.setState({
            ...this.state, 
            ...{
                isFilterMenuOpen: false, 
                includeTags: Array.isArray(e) ? e.map(e => e.value) : []
            },
        })
    }

    onFilterMenuOpen() {
        this.setState({
            ...this.state, 
            ...{isFilterMenuOpen: true}, 
        });
    }

    onFilterMenuClose() {
        this.setState({
            ...this.state, 
            ...{isFilterMenuOpen: false}, 
        });
    }
}

export default ProjectsList;
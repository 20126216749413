import React from 'react';


interface SpacerProps {
    height?: string, 
}

class Spacer extends React.Component<SpacerProps> {

    render() {
        return <div style={{"height": this.props.height || "50px"}} ></div>
    }
}

export default Spacer;
export {Spacer};
export type {SpacerProps};
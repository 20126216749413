import projectsModel from "../../models/projectsModel";
import tagsModel from "../../models/tagsModel";
import Project, { ConcreteProject } from "../Project";
import ProjectTag, { ConcreteProjectTag } from "../ProjectTag";
import { FetchCommand } from "../utils/Fetcher";

const PROJECTS_FILE = "/data/projects.json";

/**
 * Tools to fetch projects data from the server.
 */
class FetchAllProjects extends FetchCommand {

    private _promise: Promise<void> | undefined;

    public getPromise(): Promise<void> | undefined {
        return this._promise;
    }

    public run(): Promise<void> {
        
        if (this.getPromise() !== undefined) {
            return this.getPromise()!;
        }

        this._promise = fetch(PROJECTS_FILE)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("HTTP error " + response.status);
                }
                return response.json();
            }).then((json) => {

                console.log("data got");
                this.populateProjectsModel(json);

            }).catch(function (error) {
                console.error("Error in fetching tags data" + error);
            });
            
        return this._promise;
    }

    private populateProjectsModel(json: Object) {

        let missingKeys = new Set(projectsModel.getAllProjectKeys());

        for (const [key, value] of Object.entries(json)) {

            let projectData = value;

            // map project data to fill it with tags objects
            projectData["tags"] = projectData["tags"].map((tagData: { name: string; }) => {

                if(tagsModel.getByKey(tagData["name"]) === undefined) {
                    tagsModel.add(tagData["name"], new ConcreteProjectTag(tagData));
                }

                return tagsModel.getByKey(tagData["name"]);
            });

            // fill the object with fresh data or create a new object
            if (projectsModel.getProjectByKey(key) !== null) {
                projectsModel.getProjectByKey(key)!.fillFromObject(value);
            } else {
                projectsModel.addProject(key, new ConcreteProject(value));
            }

            // tell this key has been found even in fresh data
            missingKeys.delete(key);
        }

        // remove missing keys from model
        for (const key in missingKeys) {
            projectsModel.removeProject(key);
        }
    }
}

/**
 * Tool to fetch the markdown body of a project from his url.
 **/
class FetchProjectMarkdownBody extends FetchCommand {

    private _project: Project;
    private _promise: Promise<void> | undefined;
    private _markdownBody: string | undefined;

    constructor(project: Project) {
        super();
        this._project = project;
    }

    public getMarkdownBody(): string | undefined {
        return this._markdownBody;
    }

    public getPromise(): Promise<void> | undefined {

        return this._promise;
    }

    public run(): Promise<void> {

        if (this.getPromise() !== undefined) {
            return this.getPromise()!;
        }

        this._promise = fetch(this._project.markdownBodyUrl!)
            .then((response) => {

                if (!response.ok) {
                    throw new Error("HTTP error " + response.status);
                }

                return response.text();
            })
            .then((text) => {

                this._markdownBody = text;
            })
            .catch(function (error) {

                console.error("Error in fetching markdown body" + error);
            });

        return this._promise;
    }
}

// /**
//  * 
//  */
// class FetchOneProject extends FetchCommand {

//     public getPromise(): Promise<void> | undefined {
//         throw new Error("Method not implemented.");
//     }

//     public run(): Promise<void> {
//         throw new Error("Method not implemented.");
//     } 
// }

// export {FetchAllProjects, FetchOneProject};
export {FetchAllProjects, FetchProjectMarkdownBody }
export default FetchAllProjects;
import React from 'react';
import {Link} from 'react-router-dom';

class Logo extends React.Component {

    render() {

        return <span className="logo">
            <Link to={this.props.href}  >
                <span className="logo-icon">
                    <img src={this.props.logoSrc} alt={this.props.logoAlt} title={this.props.logoTitle} />
                </span>
                <span className="logo-caption">
                    {this.props.children}
                </span>
            </Link>
        </span>
    }
}

export default Logo;

import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import Logo from './Logo/Logo'
import Menu from './Menu/Menu'
import "./Header.scss"

class Header extends React.Component {

    render() {
        /* console.log(this.props) ; */

        return <header className="header">
            <Row xs={12} >
                <Col xs={10} sm={8} lg={4}>
                    <Logo href = {'/'}
                        logoSrc={"/img/logo.png"}
                        logoTitle="Emanuele Lena Software Engineer and Data Scientist" logoAlt="Emanuele Lena Software Engineer & Data Scientist" >
                        <p className="site-name">Emanuele Lena</p>
                        <p className="site-name-2">Software Engineer & Data Scientist</p>
                    </Logo>
                </Col>
                <Col xs={2} sm={4} lg={8} >
                    <div className="header-menu">
                        <Menu>
                        </Menu>
                    </div>
                </Col>
            </Row>
        </header>;

        // <MenuVoice href={"/su-di-me"} title="Qualcosa su di me">Chi sono</MenuVoice>
    }
}


export default Header;

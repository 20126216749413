import React from "react";
import { Row, Col } from "react-flexbox-grid";
import ReactMarkdown from "react-markdown";
import { Project } from "../../classes/Project";
import Spacer from "../Spacer/Spacer";
import { fetcher } from "../../classes/utils/Fetcher";
import { FetchProjectMarkdownBody } from "../../classes/project/ProjectsFetchCommands";

import './ProjectBox.scss';


interface ProjectBoxProps {

    /** The project I want to display */
    project: Project;
}

interface ProjectBoxState {
    loading: boolean, 
}

class ProjectBox extends React.Component<ProjectBoxProps, ProjectBoxState> {

    fetchMarkdownCommand: FetchProjectMarkdownBody;
    markdownBody: string | undefined;

    constructor(props: ProjectBoxProps) {
        super(props);

        this.fetchMarkdownCommand = new FetchProjectMarkdownBody(this.project);
        this.state = {loading: true};
    }

    componentDidMount() {
        fetcher.execute(this.fetchMarkdownCommand)
            .finally(() => {

                this.markdownBody = this.fetchMarkdownCommand.getMarkdownBody();

                this.setState({
                    ...this.state,
                    loading: false, 
                });
            });
    }

    hasMarkdownBody(): boolean {
        return this.project.markdownBodyUrl !== undefined;
    }

    getMarkdownBody(): string {
        
        if (this.markdownBody === undefined) return "";

        return this.markdownBody;
    }

    render() {

        if (this.state.loading) return <span>...Caricamento...</span>;

        // build object
        return (
            <div className="project-box-container">
                <Row className="project-intro">
                    <Col xs={12} md={4} lg={3} >
                        <div className="project-img">
                            <img src={this.project.imageSrc} alt={this.project.title} 
                                title={this.project.subtitle || this.project.title} />
                        </div>
                    </Col>
                    <Col xs={12} md={8} lg={9} className="project-intro-body">
                        <h1>{ this.project.title }</h1>
                        { this.project.subtitle ? <h2>{this.project.subtitle}</h2> : "" }
                        { this.project.shortDescription ? <p>{this.project.shortDescription}</p> : "" }
                    </Col>
                </Row>
                {
                    // if it exists any, print project tags
                    this.project.tags.length > 0 
                    ?   <Row>
                            <Col xs={12}>
                                <Spacer />
                                <div className="project-tags-box">
                                    <span>Tag: </span>
                                    {
                                        this.project.tags.map((tag, i) => {

                                            if (!tag) return "";
                            
                                            return (
                                                <span className="project-tag" key={i} >
                                                    {
                                                        tag.iconSrc ? <span className="project-tag-icon" key={i}>
                                                            <img src={tag.iconSrc} alt={tag.name} title={tag.name} />
                                                        </span> : ""
                                                    }
                                                    <span className="project-tag-name">
                                                        {tag.name}
                                                    </span>
                                                </span>
                                            );
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    :   ""
                }
                <Spacer />
                {
                    // If exists, print project body as markdown
                    this.hasMarkdownBody() ? 
                        <div className="project-body">
                            <ReactMarkdown >{ this.getMarkdownBody() }</ReactMarkdown>
                            <Spacer />
                        </div> : ""  
                }
                {
                   // if it exists any, print project external links
                   this.project.externalLinks.length > 0
                    ?   <Row className="project-external-links">
                            <Col xs={12}>
                                Link esterni: 
                                {
                                    this.project.externalLinks.map((link, i) => {
                                        return <span className="project-link" key={i}>
                                            <a 
                                                href={link.url} title={link.title || ""} 
                                                target={link.newPage ? "_blank" : "_self"} 
                                                rel={link.url.startsWith("http") ? "noopener noreferrer" : ""}>
                                                {link.label}
                                            </a>
                                        </span>
                                    })
                                }
                            </Col>
                        </Row>
                    :   ""
                }
            </div>
        );
    }

    get project() {
        return this.props.project;
    }
}

export default ProjectBox;
export {ProjectBox};
export type {ProjectBoxProps};

import { ProjectTag, ConcreteProjectTag } from "../classes/ProjectTag";

class TagsModel {

    public get tags(): Map<String, ProjectTag> {
        return this._tags; 
    }

    private _tags: Map<String, ProjectTag> = new Map();

    constructor(tags: Map<String, ProjectTag>) {
        this._tags = tags;
    }

    getByKey(key: string) {
        return this.tags.get(key);
    }

    add(key: string, tag: ProjectTag) {
        this.tags.set(key, tag);
    }
}

/*
let tags = new Map(Object.entries({
    "data-science": new ConcreteProjectTag({
        name: "Data Science", 
    }), 
    "r": new ConcreteProjectTag({
        iconSrc: "/img/tags/R.png", 
        name: "R", 
    }), 
    "tidyverse": new ConcreteProjectTag({
        name: "tidyverse", 
    }),
    "fun": new ConcreteProjectTag({
        name: "Fun", 
    }), 
    "python": new ConcreteProjectTag({
        iconSrc: "/img/tags/python.png", 
        name: "Python", 
    }), 
    "react-js": new ConcreteProjectTag({
        iconSrc: "/img/tags/react.png", 
        name: "React JS", 
    }), 
    "iot": new ConcreteProjectTag({
        name: "Internet of Things", 
    }), 
    "progettazione-sviluppo-software": new ConcreteProjectTag({
        name: "Progettazione e sviluppo software", 
    }), 
    "docker": new ConcreteProjectTag({
        iconSrc: "/img/tags/docker.png", 
        name: "Docker", 
    }), 
    "sistemi-distribuiti": new ConcreteProjectTag({
        name: "Sistemi Distribuiti", 
    }), 
    "mqtt": new ConcreteProjectTag({
        iconSrc: "/img/tags/mqtt.jpg", 
        name: "MQTT", 
    }),
    "flutter": new ConcreteProjectTag({
        iconSrc: "/img/tags/flutter.jpg", 
        name: "Flutter", 
    }),
    "applicazioni-mobili": new ConcreteProjectTag({
        name: "Applicazioni mobili", 
    }),
}));*/

const tagsModel: TagsModel = new TagsModel(new Map());

export default tagsModel;
export {tagsModel, TagsModel};
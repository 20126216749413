import React from 'react';
import CentredContent from '../../components/CentredContent/CentredContent';
import Spacer from '../../components/Spacer/Spacer';
import projectsModel from '../../models/projectsModel';
import ProjectsList from '../../components/ProjectBox/ProjectsList';
import tagsModel from '../../models/tagsModel';

class OtherProjects extends React.Component {
    render() {

        return (
            <div className="page-other-projects">
                <CentredContent>
                    <Spacer height="150px" />
                    <h1>Altri Progetti</h1>
                    <h2>Progetti vari, didattici e personali. </h2>
                    <Spacer height="50px" />

                    <ProjectsList projectsModel={projectsModel} tagsModel={tagsModel} />

                    <Spacer height="50px"/>
                </CentredContent>
            </div>
        );
    }
}

export default OtherProjects;
import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import './CentredContent.scss'

class CentredContent extends React.Component {

    render() {
        return <div className="centred-content">
            <Row>
                <Col xs={12} md={10} xl={8}
                    xsOffset={0} mdOffset={1} xlOffset={2} >
                    {this.props.children}
                </Col>
            </Row>
        </div>
    }
}

export default CentredContent;

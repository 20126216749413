import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import './Footer.scss'
import { Link } from 'react-router-dom';
import ContactsList from '../../components/ContactAndSocials/ContactsList'
import SocialsList from '../../components/ContactAndSocials/SocialsList'

class Footer extends React.Component {

    render() {
        return <footer className="footer">
            <div className="footer-main">
                <Row>
                    <Col xs={12} sm={6} md={3}>
                        <h4>Contatti</h4>
                        <ContactsList />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <h4>Info Legali</h4>
                        <ul>
                            <li>Emanuele Lena</li>
                            <li>CF: LNEMNL99R02F356O</li>
                            <li>P. IVA: 02988340309</li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <h4>Privacy</h4>
                        <Link to="/privacy" target="_blank" rel="noreferrer">Informativa sulla privacy e sul trattamento dei dati</Link>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <h4>Social & Link Vari</h4>
                        <SocialsList />
                    </Col>
                </Row>
            </div>
            <div className="footer-credits">
                <p>
                    Emanuele Lena@{(new Date()).getFullYear()} - 
                    ultimo aggiornamento in data 15/09/2024
                </p>
            </div>
        </footer>
    }
}

export default Footer;


/**
 * A command to fetch backend according to 
 * an arbitrary logic.
 */
abstract class FetchCommand {

    /**
     * Implement here the logic of your fetch command. 
     * 
     * @returns promise of the fetch action
     */
    public abstract run(): Promise<void>;

    /**
     * Get promise of command execution
     * 
     * @returns promise of the fetch action
     */
    public abstract getPromise(): Promise<void> | undefined;
}

/**
 * The responsable for executing fetch commands.
 * 
 * use @method execute(FetchCommand):Promise<void> to run a 
 * command and get his promise.
 */
class Fetcher {

    private static _instance = new Fetcher();
    private constructor() { };

    /**
     * Unique commands fetcher
     */ 
    public static get instance(): Fetcher { return Fetcher._instance };

    
    private _pendingCommands: Set<FetchCommand> = new Set();

    /**
     * Run @param command and get his promise
     * 
     * @param command the fetch command you wanna run
     * @returns the promise, so you can catch the end event
     */
    public execute(command: FetchCommand): Promise<void> {

        this._pendingCommands.add(command);

        return command.run().then(() => {
            this._pendingCommands.delete(command);
        });
    }

    /**
     * 
     * @returns The commands running right now
     */
    public getPendingCommands(): Iterable<FetchCommand> {
        return this._pendingCommands;
    }
}

var fetcher = Fetcher.instance;

export {FetchCommand, fetcher};
import React from 'react';
import CentredContent from '../../components/CentredContent/CentredContent';
import Spacer from '../../components/Spacer/Spacer';
import {Link} from 'react-router-dom';

class Privacy extends React.Component {
    
    render() {
        return (
            <div className="contacts-page">
                <CentredContent>
                    <Spacer height="150px" />
                    <h1>Informativa sulla Privacy e trattamento dei dati personali</h1>
                    <Spacer height="30px" />
                    <hr/>
                    
                    <p>
                        Al momento questo sito <strong>non</strong> raccoglie alcun tipo di dato e <strong>non</strong> fa uso di cookies.
                    </p>

                    <p>
                        Se mi hai contattato tramite uno dei canali <Link to="/contatti">qui riportati</Link> e mi hai comunicato 
                        qualunque tipo di informazione personale (compresa la condivisione di link, materiale fotografico, numeri di 
                        telefono, indirizzi email, ecc...), ai sensi del <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj" target="_blank" rel="noopener noreferrer">
                            regolamento nro 679/2016 dell'Unione Europea (GDPR)
                        </a>, hai diritto a fare richiesta della comunicazione, della modifica e/o della rimozione di tali dati.
                    </p>

                    <Spacer height="50px" />
                </CentredContent>
            </div>
        );
    }
}

export default Privacy;
import React from 'react'
import './Portrait.scss'

interface PortraitProps {
    src: string, 
}

/**
 * An Image container that inplement:
 * -    the possibility to set the height (the img will be automatically centred)
 * -    the possibility to use a cliccable url (w some cool effects)
 */
class Portrait extends React.Component<PortraitProps> {

    render() {

        return <div className='portrait-container'>
            <div className='portrait-box'>
                <div className='portrait-content' style={{
                    "backgroundImage": "url(" + this.props.src + ")"
                }}></div>
            </div>
        </div>
    }
}

export default Portrait;
export type {Portrait, PortraitProps};

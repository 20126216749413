import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Project } from "../../classes/Project";
import SmartImageContainer from "../SmartImageContainer/SmartImageContainer";
import Spacer from "../Spacer/Spacer";
import './ProjectBox.scss';


interface ProjectPreviewBoxProps {
    project: Project, 
    projectKey?: string, 
}

class ProjectPreviewBox extends React.Component<ProjectPreviewBoxProps> {

    render() {

        let project: Project = this.props.project;

        return (
            <div className="project-box-preview" >
                <hr />
                <Row className="project-intro">
                    <Col xs={12} md={4} lg={3} >
                        <div className="project-img">
                            <SmartImageContainer href={this.getProjectPath()} opacBg={false}
                                src={project.imageSrc} title={project.subtitle || project.title} />
                        </div>
                    </Col>
                    <Col xs={12} md={8} lg={9} className="project-intro-body">
                        <a href={this.getProjectPath()} title={project.title}>
                            <h2>{project.title}</h2>
                            { project.subtitle ? <h3>{project.subtitle}</h3> : "" }
                        </a>
                        { project.shortDescription ? <p>{project.shortDescription}</p> : "" }
                        { 
                            // if any, print project tags
                            project.tags.length > 0 
                            ?   <Row>
                                    <Col xs={12}>
                                        <Spacer />
                                        <div className="project-tags-box">
                                            <span>Tag: </span><>{
                                                project.tags.map((tag, i) => {
                    
                                                    if (!tag) return "";
                                    
                                                    return (
                                                        <span className="project-tag" key={i} >
                                                            {
                                                                tag.iconSrc ? <span className="project-tag-icon" key={i} >
                                                                    <img src={tag.iconSrc} alt={tag.name} title={tag.name} />
                                                                </span> : ""
                                                            }
                                                            <span className="project-tag-name">
                                                                {tag.name}
                                                            </span>
                                                        </span>
                                                    );
                                                })
                                            }</>
                                        </div>
                                    </Col>
                                </Row> 
                            :   "" 
                        }
                    </Col>
                </Row>
                <Spacer />
            </div>
        );
    }

    getProjectPath(): string {
        return "/progetti-vari/" + (this.props.projectKey ?? "");
    }
}

export default ProjectPreviewBox;
export {ProjectPreviewBox};
export type {ProjectPreviewBoxProps};
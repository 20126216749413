import './style/App.scss';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';

import Home from './pages/Home/Home';
import Portfolio from './pages/Portfolio/Portfolio';
import OtherProjects from './pages/OtherProjects/OtherProjects';
import Contacts from './pages/Contacts/Contacts';
// import WorkInProgress from './pages/WorkInProgress/WorkInProgress';
import Privacy from './pages/Privacy/Privacy';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ProjectPage from './pages/OtherProjects/ProjectPage';

function App() {
  return (

    <Router >
      <ScrollToTop />
      <Header />
        <Switch>
          <Route path="/privacy"  >
            <Privacy />
          </Route>
          <Route path="/contatti" >
            <Contacts />
          </Route>
          <Route path="/siti-web"  >
            <Portfolio />
          </Route>
          <Route path="/progetti-vari/:projectKey"  >
            <ProjectDynamicPage />
          </Route>
          <Route path="/progetti-vari"  >
            <OtherProjects />
          </Route>
          <Route path="/" >
            <Home />
          </Route>
        </Switch>

        <Footer />
    </Router>
  );
}

function ProjectDynamicPage() {

  let {projectKey} = useParams();

  return (<ProjectPage projectKey={projectKey}/>);
}

export default App;

import React from 'react';
import CentredContent from '../../components/CentredContent/CentredContent';
import Spacer from '../../components/Spacer/Spacer';
import ContactsList from '../../components/ContactAndSocials/ContactsList';
import SocialsList from '../../components/ContactAndSocials/SocialsList';

class Contacts extends React.Component {
    
    render() {
        return (
            <div className="contacts-page">
                <CentredContent>
                    <Spacer height="150px" />
                    <h1>Contatti</h1>
                    <Spacer height="30px" />
                    <hr/>
                    <Spacer height="10px" />
                    <h2>Vuoi contattarmi? Usa uno di questi canali</h2>
                    <ContactsList />
                    <Spacer height="30px" />
                    <h2>Social e link vari</h2>
                    <SocialsList />
                    <Spacer height="50px" />
                </CentredContent>
            </div>
        );
    }
}

export default Contacts;
import React from 'react'
import { NavLink } from 'react-router-dom';

class MenuVoice extends React.Component {

    render() {

        return <li className="menu-container">
            <NavLink to={this.props.href} title={this.props.title} onClick={this.props.onClick}>
                {this.props.children}
            </NavLink>
        </li>
    }
}

export default MenuVoice;
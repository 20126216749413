import {WebLink} from './WebLink';
import ProjectTag from './ProjectTag';

/** A generic project */
abstract class Project {

    public abstract get id(): number | undefined;
    public abstract set id(value: number | undefined);

    /** Name of the project */
    public abstract get title(): string;
    public abstract set title(value: string);
    
    /** Name of the project */
    public abstract get subtitle(): string | undefined; 
    public abstract set subtitle(value: string | undefined);

    /** Some lines which explain what the project is */
    public abstract get shortDescription(): string | undefined;;
    public abstract set shortDescription(value: string | undefined);

    /** SRC of the main image of the project */
    public abstract get imageSrc(): string;
    public abstract set imageSrc(value: string);

    /** Markdown Long Description of the project */
    public abstract get markdownBody(): string | undefined;
    public abstract set markdownBody(value: string | undefined);
    public abstract get markdownBodyUrl(): string | undefined;
    public abstract set markdownBodyUrl(value: string | undefined);

    /** Tags of the project, they may be relative to technologies, topic, etc. */
    public abstract get tags(): Array<ProjectTag>;
    public abstract set tags(value: Array<ProjectTag>);

    /** External links */
    public abstract get externalLinks(): Array<WebLink>;
    public abstract set externalLinks(value: Array<WebLink>);

    /**
     * Fill this object with values passed in a JavaScript object
     * @param data 
     */
    public fillFromObject(data:  {
        id?: number, title: string, imageSrc: string, subtitle?: string, 
        shortDescription?: string, markdownBody?: string, markdownBodyUrl?: string,
        tags?: Array<ProjectTag>, externalLinks?: Array<WebLink>}): void {
        
        this.id = data.id; 
        this.title = data.title; 
        this.subtitle = data.subtitle;
        this.shortDescription = data.shortDescription;
        this.imageSrc = data.imageSrc;
        this.markdownBody = data.markdownBody;
        this.markdownBodyUrl = data.markdownBodyUrl;

        this.tags = data.tags ? data.tags : []; 
        this.externalLinks = data.externalLinks ? data.externalLinks : [];
        
        
        // put first tags with src
        this.tags = this.tags.sort((a, b) => {
            if (a.iconSrc && !b.iconSrc) {
                return -1;
            } else if (!a.iconSrc && b.iconSrc) {
                return +1;
            } else {
                return 0;
            }
        })
    }

    public toObject(): Object {
        return {
            "id": this.id, 
            "title": this.title,
            "subtitle": this.subtitle, 
            "shortDescription": this.shortDescription, 
            "imageSrc": this.imageSrc, 
            // TODO: remove this line when all projects have a markdown body and a markdown body url
            "markdownBody": this.markdownBody, 
            "markdownBodyUrl": this.markdownBodyUrl, 
            "tags": [...this.tags.map((t)=>{ return t.toObject(); })], 
            "externalLinks": [...this.externalLinks], 
        }
    }
}

class ConcreteProject extends Project {
    
    private _id?: number;
    private _title!: string;
    private _subtitle?: string; 
    private _shortDescription?: string;
    private _imageSrc!: string;
    private _markdownBody?: string;
    private _tags: Array<ProjectTag> = [];
    private _externalLinks: Array<WebLink> = [];

    public get id(): number | undefined { return this._id; }
    public set id(value: number | undefined) { this._id = value; }

    public get title(): string { return this._title; }
    public set title(value: string) { this._title = value; }
    
    public get subtitle(): string | undefined { return this._subtitle; }
    public set subtitle(value: string | undefined) { this._subtitle = value; }

    public get shortDescription(): string | undefined { return this._shortDescription; }
    public set shortDescription(value: string | undefined) { this._shortDescription = value; }

    public get imageSrc(): string { return this._imageSrc; }
    public set imageSrc(value: string) { this._imageSrc = value; }

    public get markdownBody(): string | undefined { return this._markdownBody; }
    public set markdownBody(value: string | undefined) { this._markdownBody = value; }
    public get markdownBodyUrl(): string | undefined { return this._markdownBody; }
    public set markdownBodyUrl(value: string | undefined) { this._markdownBody = value; }

    public get tags(): Array<ProjectTag> { return this._tags; }
    public set tags(value: Array<ProjectTag>) { this._tags = value; }

    public get externalLinks(): Array<WebLink> { return this._externalLinks; }
    public set externalLinks(value: Array<WebLink>) { this._externalLinks= value; }

    constructor(data: {id?: number, title: string, imageSrc: string, subtitle?: string, 
        shortDescription?: string, markdownBody?: string, 
        tags?: Array<ProjectTag>, externalLinks?: Array<WebLink>}) {

        super();
        this.fillFromObject(data);
    } 
}

export default Project;
export { Project, ConcreteProject, ProjectTag};

